<template>

    <form @submit.prevent="create" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('church.created')" />

      <!-- Name -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('church.name') }}</label>
        <div class="col-md-7">
          <input v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }" class="form-control" type="text" name="name">
          <has-error :form="form" field="name" />
        </div>
      </div>

      <!-- Phone -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('church.phone') }}</label>
        <div class="col-md-7">
          <input v-model="form.phone" :class="{ 'is-invalid': form.errors.has('phone') }" class="form-control" type="tel" name="phone">
          <has-error :form="form" field="phone" />
        </div>
      </div>

      <!-- Email -->
      <div class="form-group row">
        <label class="col-md-3 col-form-label text-md-right">{{ $t('church.email') }}</label>
        <div class="col-md-7">
          <input v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }" class="form-control" type="email" name="email">
          <has-error :form="form" field="email" />
        </div>
      </div>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">
            {{ $t('update') }}
          </v-button>
        </div>
      </div>
    </form>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  scrollToTop: false,

  metaInfo () {
    return { title: this.$t('settings') }
  },
  data: () => ({
    form: new Form({
      name: '',
      phone: '',
      email: '',
      code: ''
    })
  }),
  async beforeRouteUpdate (to, from, next) {
    if (!this.$route.params.code) {
      next({ name: 'accounts.church.invite' });
    }
  },
  created () {
    this.form.code = this.$route.params.code;
    this.fetch();
  },
  computed: mapGetters({
    user: 'auth/user'
  }),

  methods: {
    async fetch () {
      try{
        const { data } = await axios.post('/api/v1/invite/verify', {code: this.form.code});

        const church = data.church;
        this.form.name = church.name;
        this.form.phone = church.phone;
        this.form.email = church.email;
      } catch (e) {
        this.$router.push({name: 'accounts.church.invite'});
      }
    },
    async create () {
      const { data } = await this.form.post('/api/v1/user/me/church');
      this.$store.dispatch('account/updateChurch', { church: data.church })
          .then(() => this.$router.push({ name: 'accounts.church' }))
    }
  }
}
</script>
